<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">
                                    Link video hoặc id cần tăng
                                    {{
                                        $route.path.includes("sub")
                                            ? "sub"
                                            : $route.path.includes("comment")
                                            ? "comment"
                                            : "view"
                                    }}:
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <label v-if="type == 'subscribe' && !object_id" class="text-danger d-block mt-2 bold"
                                    >Lưu ý: Để tăng Sub cho Channel bạn cần nhập Link 1 video bất kỳ của Channel, hệ
                                    thống sẽ tự động Get ID Channel cần tăng Sub.</label
                                >
                                <h6 v-if="channel_name.length > 0 && object_id.length > 0" class="d-block mt-2">
                                    Tăng Sub cho Channel: <span class="green bold">{{ channel_name }}</span>
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Loại Seeding cần tăng:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select v-model="type" class="custom-select select-light">
                                    <option v-if="$route.path.includes('sub')" value="subscribe"
                                        >Theo Dõi Kênh Youtube</option
                                    >
                                    <option v-else-if="$route.path.includes('comment')" value="comment"
                                        >Tăng Comment Video Youtube</option
                                    >
                                    <option v-else-if="$route.path.includes('view')" value="view"
                                        >Tăng View Video Youtube</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chế độ bảo hành:</h6>
                            </div>
                            <div class="col-md col-12 text-left">
                                <select
                                    v-model="is_warranty"
                                    class="form-control custom-select select-light"
                                    required="required"
                                    :disabled="type == 'subscribe'"
                                >
                                    <option :value="false">Không bảo hành (Bảo hành nếu tụt quá 70%)</option>
                                    <option :value="true">Bảo hành (Bảo hành nếu tụt quá 10%)</option>
                                </select>
                                <div v-show="is_warranty" class="row mt-4">
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày bắt đầu</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_start"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="minDatetime"
                                        ></datetime>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Chọn ngày kết thúc</label>
                                        <datetime
                                            class="theme-green"
                                            type="datetime"
                                            v-model="time_expired"
                                            format="yyyy-MM-dd HH:mm"
                                            input-class="form-control"
                                            :min-datetime="time_start"
                                            :max-datetime="increaseDateByNumber(time_start, date_warranty)"
                                        ></datetime>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="type != 'comment' && type != 'share_group'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input v-model.number="quantity" type="number" class="form-control input-light" />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12"></div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body py-2">
                                        <h6 class="font-13 mb-0 text-justify">
                                            Mẹo nhỏ: Hệ thống ưu tiên chạy các job giá cao trước nên nếu bạn cần gấp bạn
                                            có thể set giá job của mình cao hơn 1 vài đồng để chạy nhanh nhất có thể
                                            nhé.
                                        </h6>
                                        <h6 class="font-14 mb-0 text-danger font-bold">
                                            Lưu ý: Nên buff dư thêm 30 - 50% trên tổng số lượng để tránh tụt.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                                <h6 class="text-danger bold font-13">
                                    Giá thấp nhất: {{ price_default }} {{ currency }}
                                </h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    type="number"
                                    placeholder="Giá tiền bạn muốn bid cho mỗi tương tác"
                                    v-model="price"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div v-show="type === 'comment' || type === 'review'" class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Giá tiền mỗi tương tác:</h6>
                            </div>
                            <div class="col-md col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h6 class="text-danger font-bold font-14">
                                            Nghiêm cấm bình luận những nội có cử chỉ, lời nói thô bạo, khiêu khích, trêu
                                            ghẹo, xúc phạm nhân phẩm, danh dự của Cá nhân hoặc Tổ chức.
                                        </h6>
                                        <h6 class="text-danger font-bold font-14">
                                            Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và
                                            phải chịu hoàn toàn trách nhiệm trước pháp luật.
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <label class="mr-2 mt-3"
                                        >Nhập nội dung bình luận (<label class="font-bold"
                                            >Mỗi dòng tương đương với 1 bình luận</label
                                        >):</label
                                    >
                                    <textarea
                                        class="form-control input-light"
                                        rows="10"
                                        v-model="list_messages"
                                        placeholder="Nhập nội dung seeding, mỗi dòng 1 nội dung được tính tiền là 1 bình luận"
                                    ></textarea>
                                    <span class="badge badge-primary text-white py-1 float-right"
                                        >Số lượng: {{ quantity }}</span
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff <span class="bold">{{ quantity }} {{ type }}</span> với giá
                                            <span class="green bold">{{ priceSingle | formatNumber }}</span>
                                            {{ currency }} / {{ type }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>- Hệ thống sử dụng tài khoản người VN</p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    50k, số lượng càng lớn thì thời gian chạy càng lâu
                                </p>
                                <p>
                                    - Thời gian tăng {{ type }} tính từ ngày bắt đầu cho đến ngày kết thúc job, tối đa
                                    là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng sẽ tự động hoàn lại số tiền
                                    seeding chưa tăng cho bạn <b class="font-bold">trong vòng từ 2 đến 4 ngày</b>
                                </p>
                                <p>- Vui lòng lấy đúng link trang cá nhân và không nhập id khác</p>
                                <p>
                                    - Check kỹ job tránh tạo nhầm, tính năng đang trong giai đoạn thử nghiệm nên sẽ
                                    không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <youtubelogs :panel="panel" :type="type"></youtubelogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, getYoutubeID } from "../../../../helpers"
import { orderSeedingJob, checkInfoJobYoutubeNew } from "../../../../api/services/youtube"
import youtubelogs from "./youtube-logs"
export default {
    name: "youtube-order",
    components: { youtubelogs },
    data() {
        return {
            object_id: "",
            type: "",
            is_warranty: false,
            time_start: new Date().toISOString(),
            minDatetime: new Date().toISOString(),
            time_expired: "",
            date_warranty: 7,
            price: 0,
            is_filter: false,
            panel: "order",
            quantity: 100,
            price_default: 0,
            notes: "",
            list_messages: "",
            channel_name: ""
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 76)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.quantity
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        router(value) {
            if (value.includes("comment")) {
                this.type = "comment"
            } else if (value.includes("sub")) {
                this.type = "subscribe"
            } else this.type = "view"
        },
        list_messages(value) {
            this.quantity = value ? value.match(/.+/g).length : 0
        },
        async object_id() {
            if (this.object_id.indexOf("youtube.com") > -1) {
                this.object_id = await this.getChannelId(this.object_id)
            }
        },
        type(value) {
            value += "_youtube"
            let getPackage = "seeding_by_workers_" + (this.is_warranty ? value + "_warranty" : value)
            let price = this.prices.find(price => price.package_name === getPackage)
            if (price) {
                this.price = price.price
                this.price_default = price.price
            }
            if (value == "subscribe") {
                this.is_warranty = false
            }
        },
        is_warranty(value) {
            let type = this.type + "_youtube"
            let getPackage = value ? type + "_warranty" : type
            let price = this.prices.find(price => price.package_name.includes(getPackage))
            if (price) {
                this.price = price.price
                this.price_default = price.price
            }
        }
    },
    async created() {
        if (this.$route.path.includes("comment")) {
            this.type = "comment"
        } else if (this.$route.path.includes("sub")) {
            this.type = "subscribe"
        } else this.type = "view"
        this.getPriceSeedingType(this.type)
        this.time_expired = this.increaseDateByNumber(this.time_start, this.date_warranty)
    },
    methods: {
        order: async function() {
            if (!this.object_id) {
                this.$toastr.error(`Vui lòng nhập Link video hoặc id cần tăng ${this.type}`)
                return
            }
            if (this.price >= 2 * this.price_default) {
                this.$swal(
                    "Thông báo",
                    "Giá bạn đang chọn cao hơn giá bình thường nhiều lần, vui lòng chỉnh lại giá!",
                    "error"
                )
                return
            }
            if (this.type === "comment" && this.list_messages.length < 1) {
                this.$toastr.error("Vui lòng nhập nội dung cần comment")
                return
            }
            if (this.type === "comment") {
                if (
                    this.list_messages.length > 1 &&
                    this.list_messages
                        .toLowerCase()
                        .match(
                            /dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g
                        )
                ) {
                    this.$swal(
                        "Lỗi",
                        "Nội dung bình luận của bạn vi phạm tiêu chuẩn cộng đồng, hãy nhập lại nội dung cần bình luận!",
                        "error"
                    )
                    return
                }
            }
            if (this.price < this.price_default) {
                this.$swal("Lỗi", "Giá cài đặt phải lớn hơn hoặc bằng giá thấp nhất", "error")
                return
            }
            this.$swal({
                title: "Xác nhận",
                text: `Bạn có chắc chắn muốn Order ${this.quantity} ${this.type.toUpperCase()} với giá: ${this.price} ${
                    this.currency
                }/1 ${this.type.toUpperCase()} ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                } else if (result.dismiss === this.$swal.cancel) {
                    this.$swal("Thông tin", "Bạn đã hủy bỏ Order hiện tại!", "info")
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link:
                    (this.type === "subscribe"
                        ? "https://www.youtube.com/channel/"
                        : "https://www.youtube.com/watch?v=") + this.object_id,
                type: this.type,
                object_id: this.object_id,
                object_view: this.object_view ? this.object_view : this.object_id,
                quantity: this.quantity,
                prices: this.price * this.quantity,
                time_expired: this.time_expired,
                provider: "youtube"
            }
            if (this.type === "comment") {
                postData.list_messages = this.list_messages.match(/.+/g)
            }
            if (this.type === "subscribe") {
                postData.object_view = this.object_id
            }
            let data = await orderSeedingJob(postData)
            if (!data) {
                this.$toastr.error("Hiện tại không thể tạo đơn, vui lòng thử lại sau ít phút !")
                return
            }
            if (data.status === 200 && data.success) {
                this.$swal(
                    "Thành công",
                    "Tạo tiến trình seeding thành công, hệ thống sẽ tự động tăng theo thời gian bạn đã chọn</br></br>Nếu không đủ lượt seeding hệ thống sẽ tự động hoàn lại số tiền chưa chạy",
                    "success"
                )
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        getPriceSeedingType: function(type) {
            let price = this.prices.find(price => price.package_name.includes(type))
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },

        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getChannelId: async function(url) {
            if (url.includes("channel")) {
                this.$swal(
                    "Lỗi",
                    "Bạn cần nhập vào link video bất kỳ của Kênh, hệ thống sẽ tự động Get ID Kênh cần tăng!",
                    "error"
                )
                return ""
            }

            this.$toastr.success("Hệ thống đang tự động get id cho link Youtube bạn nhập vào!")
            if (url.indexOf("youtube") < 0 && !url.includes("youtu.be")) return url

            const youtubeID = getYoutubeID(url)
            if (!youtubeID || this.type !== "subscribe") {
                this.$toastr.success("Đã tự động get id cho bạn")
                return youtubeID
            }

            this.object_view = youtubeID
            const param = { object_id: youtubeID, type: "video" }
            this.$store.commit("APP_LOADING", true)
            const data = await checkInfoJobYoutubeNew(param)
            this.$store.commit("APP_LOADING", false)
            if (!data.data || data.status !== 200) {
                this.$toastr.error("Không tự động get id được")
                return url
            }

            this.$toastr.success("Đã tự động get id cho bạn")
            this.channel_name = data.data.items[0].snippet.channelTitle
            return data.data.items[0].snippet.channelId
        }
    }
}
</script>
